var socket = null; //实例对象
var lockReconnect = false; //是否真正建立连接
var timeout = 30 * 1000; //30秒一次心跳
var timeoutObj = null; //心跳倒计时
var serverTimeoutObj = null; //服务心跳倒计时
var timeoutnum = null; //断开 重连倒计时
var reconnectNum = 0;
var reconnecting = false;

const generateUUID = () => {
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const initWebSocket = async () => {
  if ("WebSocket" in window) {
    var username = generateUUID();
    const wsUrl = "/ws/" + username;
    socket = new WebSocket(wsUrl);
    socket.onerror = webSocketOnError;
    socket.onmessage = webSocketOnMessage;
    socket.onclose = closeWebsocket;
    socket.onopen = openWebsocket;
  } else {
    alert("您的浏览器不支持websocket，请更换Chrome或者Firefox");
  }
};

//建立连接
const openWebsocket = (e) => {
  start();
};

const start = () => {
  //开启心跳
  timeoutObj && clearTimeout(timeoutObj);
  serverTimeoutObj && clearTimeout(serverTimeoutObj);
  timeoutObj = setTimeout(function () {
    //这里发送一个心跳，后端收到后，返回一个心跳消息
    if (socket.readyState == 1) {
      //如果连接正常
      // socket.send("heartbeat");
    } else {
      //否则重连
      reconnect();
    }
    serverTimeoutObj = setTimeout(function () {
      //超时关闭
      socket.close();
    }, timeout);
  }, timeout);
};

//重新连接
const reconnect = () => {
  if (lockReconnect) {
    return;
  }
  lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  timeoutnum && clearTimeout(timeoutnum);
  if (reconnectNum)
    timeoutnum = setTimeout(function () {
      //新连接
      console.log("websocket重连");
      initWebSocket();
      lockReconnect = false;
    }, 5000);
};

//重置心跳
const reset = () => {
  //清除时间
  clearTimeout(timeoutObj);
  clearTimeout(serverTimeoutObj);
  //重启心跳
  start();
};

const sendWebsocket = (e) => {
  socket.send("heartbeat");
};

const webSocketOnError = (e) => {
  console.log("websocket错误");
  // initWebSocket();
  // reconnect();
};

//服务器返回的数据
const webSocketOnMessage = (e) => {
  //window自定义事件[下面有说明]
  reconnectNum = 0;
  reconnecting = false;
  window.dispatchEvent(
    new CustomEvent("onmessageWS", {
      detail: {
        data: e.data,
      },
    })
  );
  reset();
};

const closeWebsocket = (e) => {
  console.log("websocket断开");
  if (reconnecting == false) {
    if (reconnectNum < 1) {
      console.log("websocket重连-5000");
      reconnectNum++;
      reconnecting = true;
      setTimeout(() => {
        reconnecting = false;
        reconnect();
      }, 5000);
      return;
    }
    if (reconnectNum < 2) {
      console.log("websocket重连-60000");
      reconnectNum++;
      reconnecting = true;
      setTimeout(() => {
        reconnecting = false;
        reconnect();
      }, 60000);
      return;
    }
    if (reconnectNum < 3) {
      console.log("websocket重连-180000");
      reconnectNum++;
      reconnecting = true;
      setTimeout(() => {
        reconnecting = false;
        reconnect();
      }, 180000);
      return;
    }
    if (reconnectNum < 4) {
      console.log("websocket重连-300000");
      reconnectNum++;
      reconnecting = true;
      setTimeout(() => {
        reconnecting = false;
        reconnect();
      }, 300000);
      return;
    }
  } else {
    return;
  }
};

//断开连接
const close = () => {
  //WebSocket对象也有发送和关闭的两个方法，只需要在自定义方法中分别调用send()和close()即可实现。
  socket.close();
};
//具体问题具体分析,把需要用到的方法暴露出去
export default { initWebSocket, sendWebsocket, webSocketOnMessage, close };
