import { request } from "./http";

let url = "/view/";

export function getProductAmountMonth(data) {
  return request("get", url + "getProductAmountMonth", data);
}

export function getArticleInfo(data) {
  return request("get", url + "getArticleInfo", data);
}

export function getWorkShopInfo(data) {
  return request("get", url + "getWorkShopInfo", data);
}

export function getElectricGasAmountMonth(data) {
  return request("get", url + "getElectricGasAmountMonth", data);
}

export function getWarnInfo(data) {
  return request("get", url + "getWarnInfo", data);
}

export function getDeviceStatus(data) {
  return request("get", url + "getDeviceStatus", data);
}

export function getSpeedList(data) {
  return request("get", url + "getSpeedList", data);
}

export function getOnlineCount(data) {
  return request("get", url + "getOnlineCount", data);
}